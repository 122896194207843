<template>
<div class="multi-select" v-on-clickaway="away">
    <div class="input-container" @click="showDropDown = !showDropDown">
        <label>{{ title }}</label>
        <div class="arrow"></div>
    </div>
    <div class="dropdown" v-if="showDropDown">
        <ul>
            <li v-for="option in options" @click="select(option)" :class="{'item-is-selected': isSelected(option)}">{{ option.title }}</li>
        </ul>
    </div>
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [ clickaway ],

    props: {
        title: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            showDropDown: false
        }
    },

    methods: {
        select(option) {
            if(this.isSelected(option)) return

            this.$emit('select', option)
            this.showDropDown = !this.showDropDown
        },

        away() {
          this.showDropDown = false
        },

        isSelected(opt) {
            let rr = this.selected.find(element => {
                console.log('element', element)
                return element.value == opt.value
            })

            if(rr) {
                return true
            }

            return false
        }
    }
}
</script>

<style scoped>

</style>
