/***********************************************************************************************
 * Custom counter library                                                                      *
 *                                                                                             *
 * Script usage:                                                                               *
 *                                                                                             *
 * 1. add to element class="js-counter"                                                        *
 * 2. to start animation element must have this data attributes:                               *
 *  2.1 data-from - integer, let's counter to know from what number start counter animation    *
 *  2.2 data-until - integer, let's counter to know when finish counter animation              *
 *  2.3 data-duration -  integer, set counter animation duration time in Millisecond           *
 *  2.4 data-slow-down-speed - integer, set counter how fast slow down animation at the end    *
 *  2.5 data-slow-down-from - integer, make counter slow down from current number              *
 *  2.6 data-delay - integer, add delay to counter animation                                   *
 *  2.7 data-text-after - string, add text at the end of counter when finish animate           *
 **********************************************************************************************/

import { isInViewport } from './helpers'

// Add event listener to start animation from animations.js
document.addEventListener('loadCounter', function(e) {
    startCounters(e.detail.id)
})

// Add event listeners to start animation when window is loaded or on scroll event
window.addEventListener('load', callCounterWithoutAnimator)
window.addEventListener('scroll', callCounterWithoutAnimator)

// Call element counter if we do not trigger them from animation.js
function callCounterWithoutAnimator() {
    let counters = document.querySelectorAll('.js-counter:not(.animate)')

    counters.forEach(function(el) {
        // Check if element are in view port
        let inView = isInViewport(el)

        if(inView)
            startCounters(el.getAttribute('id'))
    })
}

// Dedicated function to call counter for current element from outside
export function callCounter(id) {
    document.dispatchEvent(new CustomEvent('loadCounter', {
        detail: {
            id: id
        }
    }))
}

// start counter animation for single element by given id
function startCounters(id = null) {
    if(id) {
        let el = document.getElementById(id)
        let allowCount = checkIfCanCount(el)

        if(allowCount) {
            let elData = getElementData(el)
            animateCounter(el, elData.from, elData.until, elData.textAfter, elData.duration, elData.slowDownSpeed, elData.slowDownFrom, elData.delay)
        }
    }
}

// if counter starts don't allow call counter second time
function checkIfCanCount(el) {
    if(el.classList.contains('animate-counter')) {
        return false
    }

    el.classList.add('animate-counter')

    return true
}

// Get counter element data atributes
function getElementData(el) {
    return {
        from: transformValue(el.getAttribute('data-from'), 'int'),
        until: transformValue(el.getAttribute('data-until'), 'int'),
        textAfter: el.getAttribute('data-text-after'),
        duration: transformValue(el.getAttribute('data-duration'), 'int'),
        slowDownSpeed: transformValue(el.getAttribute('data-slow-down-speed'), 'int'),
        slowDownFrom: transformValue(el.getAttribute('data-slow-down-from'), 'int'),
        delay: transformValue(el.getAttribute('data-delay'), 'int')
    }
}

// Transform string to selected type
function transformValue(val, type) {
    let newVal

    switch (type) {
        case 'int':
            newVal = val ? parseInt(val) : 0
            break;
        default:
            newVal = val
    }

    return newVal
}

// Main counter animation logic
function animateCounter(element, minVal, maxVal, textAfter, duration = null, slowDownSpeed = null, slowDownFrom = null, delay = null) {
    // if duration do not set, then set to 2000 by default
    if(!duration)
        duration = 2000

    // calculate how often change numbers
    let interval = calcDuration(duration, slowDownSpeed, slowDownFrom) / (maxVal - minVal)
    let nextInterval = delay ?? 0
    let slowDownIndex = 1

    for(let i = minVal; i <= maxVal; i++) {
        setTimeout(() => {

            if(i === maxVal)
                element.innerHTML = i + textAfter
            else
                element.innerHTML = i
        }, nextInterval)

        // if element have slowdown data increase interval duration
        if(slowDownSpeed && slowDownFrom && (interval * maxVal) < duration) {
            if((i + slowDownFrom) >= maxVal ) {
                nextInterval = nextInterval + (slowDownSpeed * slowDownIndex)
                slowDownIndex++
            }
        }

        nextInterval = nextInterval + interval
    }
}

// calculate total duration time with slowdown animation
function calcDuration(duration, speed, times) {
    let totalSlowDown = 0

    for(let i = 1; i <= times; i++) {
        totalSlowDown = totalSlowDown + (i * speed)
    }

    if(duration <= totalSlowDown) {
        console.error('Slow down (speed * from) can\'t be les then: duration. Duration: ' + duration + ', slow dow duration: ' + totalSlowDown )
        totalSlowDown = 0
    }

    return duration - totalSlowDown
}
