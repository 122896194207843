var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.away,
          expression: "away",
        },
      ],
      staticClass: "multi-select",
    },
    [
      _c(
        "div",
        {
          staticClass: "input-container",
          on: {
            click: function ($event) {
              _vm.showDropDown = !_vm.showDropDown
            },
          },
        },
        [
          _c("label", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", { staticClass: "arrow" }),
        ]
      ),
      _vm._v(" "),
      _vm.showDropDown
        ? _c("div", { staticClass: "dropdown" }, [
            _c(
              "ul",
              _vm._l(_vm.options, function (option) {
                return _c(
                  "li",
                  {
                    class: { "item-is-selected": _vm.isSelected(option) },
                    on: {
                      click: function ($event) {
                        return _vm.select(option)
                      },
                    },
                  },
                  [_vm._v(_vm._s(option.title))]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }