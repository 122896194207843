var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "project-filters", attrs: { id: "projectsFilters" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "filters" }, [
          _c("div", { staticClass: "selects" }, [
            _c(
              "div",
              { staticClass: "col animate -from-top-to-bottom" },
              [
                _c("multiselect", {
                  attrs: {
                    title: "Plotas",
                    options: _vm.options1,
                    selected: _vm.selected1,
                  },
                  on: { select: _vm.addToOption1 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col animate -from-top-to-bottom" },
              [
                _c("multiselect", {
                  attrs: {
                    title: "Miegamųjų sk.",
                    options: _vm.options3,
                    selected: _vm.selected3,
                  },
                  on: { select: _vm.addToOption3 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col animate -from-top-to-bottom" },
              [
                _c("multiselect", {
                  attrs: {
                    title: "Aukštų sk.",
                    options: _vm.options4,
                    selected: _vm.selected4,
                  },
                  on: { select: _vm.addToOption4 },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "selected" },
            [
              _vm._l(_vm.selected1, function (selected) {
                return _vm.selected1.length > 0
                  ? _c("div", { staticClass: "select" }, [
                      _c("span", [_vm._v("Plotas: " + _vm._s(selected.title))]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "close",
                        on: {
                          click: function ($event) {
                            return _vm.removeFromOption1(selected)
                          },
                        },
                      }),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.selected2, function (selected) {
                return _vm.selected2.length > 0
                  ? _c("div", { staticClass: "select" }, [
                      _c("span", [_vm._v("Klasė: " + _vm._s(selected.title))]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "close",
                        on: {
                          click: function ($event) {
                            return _vm.removeFromOption2(selected)
                          },
                        },
                      }),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.selected3, function (selected) {
                return _vm.selected3.length > 0
                  ? _c("div", { staticClass: "select" }, [
                      _c("span", [
                        _vm._v("Miegamųjų sk.: " + _vm._s(selected.title)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "close",
                        on: {
                          click: function ($event) {
                            return _vm.removeFromOption3(selected)
                          },
                        },
                      }),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.selected4, function (selected) {
                return _vm.selected4.length > 0
                  ? _c("div", { staticClass: "select" }, [
                      _c("span", [
                        _vm._v(" Aukštų sk.: " + _vm._s(selected.title)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "close",
                        on: {
                          click: function ($event) {
                            return _vm.removeFromOption4(selected)
                          },
                        },
                      }),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.selected1.length > 0 ||
              _vm.selected2.length > 0 ||
              _vm.selected3.length > 0 ||
              _vm.selected4.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "select clear-all-list",
                      on: { click: _vm.emptyEverything },
                    },
                    [_vm._v("Išvalyti visus")]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "project-list" },
      _vm._l(_vm.projects, function (project, index) {
        return _c(
          "div",
          {
            staticClass: "project-card animate -fade-in",
            on: {
              mouseover: function ($event) {
                return _vm.getWidth(index)
              },
            },
          },
          [
            project.title !== "sugestion"
              ? _c(
                  "div",
                  {
                    staticClass: "card ",
                    on: {
                      click: function ($event) {
                        return _vm.openLink("/projektai/" + project.slug)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: project.img_thumbnail,
                        alt: "Project " + project.title + " cover image",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "title", attrs: { id: "title" + index } },
                      [_vm._v(_vm._s(project.title))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "on-hover" }, [
                      _c("div", {
                        staticClass: "bottom-line",
                        attrs: { id: "titleLine" + index },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "custom-box" }),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "card-to-contacts",
                    on: {
                      click: function ($event) {
                        return _vm.openLink("/kontaktai")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/assets/products/gradient.jpg",
                        alt: "green image",
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(1, true),
                  ]
                ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _c("div", {
          staticClass: "prev",
          on: {
            click: function ($event) {
              return _vm.fetchProjects(_vm.pagination.prev_page_url, true)
            },
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.pages, function (page) {
          return _c(
            "div",
            {
              staticClass: "page",
              class: { active: page.active },
              on: {
                click: function ($event) {
                  return _vm.fetchProjects(page.url, true)
                },
              },
            },
            [
              _vm._v(
                "\n                " + _vm._s(page.label) + "\n            "
              ),
            ]
          )
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "next",
          on: {
            click: function ($event) {
              return _vm.fetchProjects(_vm.pagination.next_page_url, true)
            },
          },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("p", [_vm._v("Filtras")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "on-top" }, [
      _c("img", {
        attrs: { src: "/assets/svg/question_mark.svg", alt: "Question icon" },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "des-1" }, [_vm._v("Nerandate")]),
      _vm._v(" "),
      _c("p", { staticClass: "des-2" }, [
        _c("b", [_vm._v("patinkančio projekto?")]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "des-3" }, [_vm._v("Sukursime Jums")]),
      _vm._v(" "),
      _c("p", { staticClass: "des-4" }, [_vm._v("individualų namo projektą!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }