export function isInViewport(element, list = false) {
    let rect

    if(list)
        rect = element[0].getBoundingClientRect()
    else
        rect = element.getBoundingClientRect()

    return (
        rect.top >= (window.scrollY <= 0 ? -60 : -60) &&
        rect.bottom <= ((window.innerHeight + ((rect.bottom - rect.top) / 2)) || (document.documentElement.clientHeight + ((rect.top - rect.bottom) / 2)))
    )
}
