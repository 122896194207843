<template>
    <div>
        <div id="projectsFilters" class="project-filters">
            <div class="title"><p>Filtras</p></div>
            <div class="filters">
                <div class="selects">
                    <div class="col animate -from-top-to-bottom">
                        <multiselect
                         title="Plotas"
                         :options="options1"
                         :selected="selected1"
                         @select="addToOption1">
                        </multiselect>
                    </div>
<!--                    <div class="col animate -from-top-to-bottom">-->
<!--                        <multiselect-->
<!--                         title="Klasė"-->
<!--                         :options="options2"-->
<!--                         :selected="selected2"-->
<!--                         @select="addToOption2">-->
<!--                        </multiselect>-->
<!--                    </div>-->
                    <div class="col animate -from-top-to-bottom">
                        <multiselect
                         title="Miegamųjų sk."
                         :options="options3"
                         :selected="selected3"
                         @select="addToOption3">
                        </multiselect>
                    </div>
                    <div class="col animate -from-top-to-bottom">
                        <multiselect
                         title="Aukštų sk."
                         :options="options4"
                         :selected="selected4"
                         @select="addToOption4">
                        </multiselect>
                    </div>
                </div>
                <div class="selected">
                    <div class="select" v-if="selected1.length > 0" v-for="selected in selected1">
                        <span>Plotas: {{ selected.title }}</span>
                        <div class="close" @click="removeFromOption1(selected)"></div>
                    </div>
                    <div class="select" v-if="selected2.length > 0" v-for="selected in selected2">
                        <span>Klasė: {{ selected.title }}</span>
                        <div class="close" @click="removeFromOption2(selected)"></div>
                    </div>
                    <div class="select" v-if="selected3.length > 0" v-for="selected in selected3">
                        <span>Miegamųjų sk.: {{ selected.title }}</span>
                        <div class="close" @click="removeFromOption3(selected)"></div>
                    </div>
                    <div class="select" v-if="selected4.length > 0" v-for="selected in selected4">
                        <span> Aukštų sk.: {{ selected.title }}</span>
                        <div class="close" @click="removeFromOption4(selected)"></div>
                    </div>

                    <div class="select clear-all-list" v-if="selected1.length > 0 || selected2.length > 0 || selected3.length > 0 || selected4.length > 0" @click="emptyEverything">Išvalyti visus</div>
                </div>
            </div>
        </div>
        <div class="project-list">
            <div class="project-card animate -fade-in"
                 v-for="(project, index) in projects"
                 @mouseover="getWidth(index)"
            >
                <div v-if="project.title !== 'sugestion'" class="card " @click="openLink('/projektai/' + project.slug)">
                    <img :src="project.img_thumbnail" :alt="'Project ' + project.title + ' cover image'">
                    <label class="title" :id="'title' + index">{{ project.title }}</label>
                    <div class="on-hover">
                        <div class="bottom-line" :id="'titleLine' + index"></div>
                        <div class="custom-box"></div>
                    </div>
                </div>
                <div v-else class="card-to-contacts" @click="openLink('/kontaktai')">
                    <img src="/assets/products/gradient.jpg" alt="green image">
                    <div class="on-top">
                        <img src="/assets/svg/question_mark.svg" alt="Question icon">
                        <p class="des-1">Nerandate</p>
                        <p class="des-2"><b>patinkančio projekto?</b></p>
                        <p class="des-3">Sukursime Jums</p>
                        <p class="des-4">individualų namo projektą!</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination">
            <div class="prev" @click="fetchProjects(pagination.prev_page_url, true)"></div>
            <div class="page" v-for="page in pages" @click="fetchProjects(page.url, true)" :class="{active: page.active}">
                {{ page.label }}
            </div>
            <div class="next" @click="fetchProjects(pagination.next_page_url, true)"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Multiselect from './FilterMultiSelect'

export default {
    components: { Multiselect },

    data() {
        return {
            projects: [],
            elements: [],
            selected1: [],
            selected2: [],
            selected3: [],
            selected4: [],
            options1: [],
            options2: [
                {title: 'A', value: 'A'},
                {title: 'B', value: 'B'},
                {title: 'C', value: 'C'},
            ],
            options3: [],
            options4: [],
            pagination: {
                current_page: 1,
                next_page_url: '',
                prev_page_url: '',
                last_page: 1
            },
            pages: []
        }
    },

    methods: {
        openLink(url) {
          window.open( url,"_self")
        },

        getWidth(index) {
            setTimeout(() => {
                let el = document.getElementById('title' + index)

                if(el) {
                    el = el.clientWidth
                    document.getElementById('titleLine' + index).style.width = 'calc(100% - 15px - ' + el + 'px)'
                }
            }, 1)
        },

        filter() {
            this.fetchProjects('/api/all-projects')
        },

        fetchProjects(url, scrol = false) {
            axios.post(url, {
                area_m2: this.selected1,
                class: this.selected2,
                rooms: this.selected3,
                floors: this.selected4,
            })
            .then(res => {
                this.projects = res.data.data
                this.pagination = res.data

                this.pagination.current_page = res.data.current_page
                this.pagination.next_page_url = res.data.next_page_url
                this.pagination.prev_page_url = res.data.prev_page_url
                this.pagination.last_page = res.data.last_page

                this.pages = [];

                if(res.data.last_page > 0) {
                    let max = 5
                    let current = 1
                    let from = 1

                    if(res.data.last_page > max) {
                        from = res.data.current_page - 2

                        if(from <= 0) from = 1
                    }

                    for(let i = from; i <= res.data.last_page; i++) {
                        if(current <= max) {
                            this.pages.push({
                                label: i,
                                url: '/api/all-projects?page=' + i,
                                active: i === res.data.current_page
                            })
                            current++
                        }
                    }
                }

                let rand = this.getRandomInt(8)

                this.projects.splice(rand, 0, {
                    'title': 'sugestion'
                })

                if(scrol)
                    this.scrollToTop()

                console.log(this.projects)
            })
        },

        getRandomInt(max) {
          return Math.floor(Math.random() * max);
        },

        addToOption1(e) {
            this.selected1.push(e)
            this.filter()
        },

        addToOption2(e) {
            this.selected2.push(e)
            this.filter()
        },

        addToOption3(e) {
            this.selected3.push(e)
            this.filter()
        },

        addToOption4(e) {
            this.selected4.push(e)
            this.filter()
        },

        removeFromOption1(opt) {
            this.selected1 = this.selected1.filter(el => el.value != opt.value)
            this.filter()
        },

        removeFromOption2(opt) {
            this.selected2 = this.selected2.filter(el => el.value != opt.value)
            this.filter()
        },

        removeFromOption3(opt) {
            this.selected3 = this.selected3.filter(el => el.value != opt.value)
            this.filter()
        },

        removeFromOption4(opt) {
            this.selected4 = this.selected4.filter(el => el.value != opt.value)
            this.filter()
        },

        emptyEverything() {
            this.selected1 = []
            this.selected2 = []
            this.selected3 = []
            this.selected4 = []
            this.filter()
        },

        setFiltersData() {
            axios.get('/api/all-projects/filters-data')
                .then(res => {
                    console.log('res: ', res)
                    this.options1 =[];
                    this.options3 =[];
                    this.options4 =[];
                    let area = res.data.area.min;

                    while(area < res.data.area.max) {
                        let min = area
                        let max = area + 50

                        area = max

                        if(min >= 230) {
                            area = res.data.area.max
                            this.options1.push({title: min + 'm2 ir daugiau', value: min + '-' + res.data.area.max})
                        } else {
                            if(max > res.data.area.max) {
                                max = res.data.area.max
                            }
                            this.options1.push({title: min + 'm2 - ' + max + 'm2', value: min + '-' + max})
                        }

                        console.log(this.options1)
                    }

                    for(let i = res.data.rooms.min; i <= res.data.rooms.max; i++) {
                        this.options3.push({title: i, value: i})
                    }

                    for(let i = res.data.floors.min; i <= res.data.floors.max; i++) {
                        this.options4.push({title: i, value: i})
                    }
                })
        },

        scrollToTop() {
            document.getElementById("projectsFilters").scrollIntoView({
              behavior: 'smooth'
            });
        }
    },

    mounted() {
        this.fetchProjects('/api/all-projects')
        this.setFiltersData()
    }
}
</script>

<style scoped>

</style>
