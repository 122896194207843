import HomePageSlide from "./HomePageSlide";

require('./HomePageSlide.js')
import Splide from '@splidejs/splide';
// import HomePageSlide from './homePageSlide'

window.addEventListener("load", function(){
    /******************************
     *     Header image resize
     *****************************/
    let defaultHeader = document.getElementById('defImgContainer')

    if(defaultHeader) {
        setDefHeaderImgWidth()
    }

    function getPageTopLeft(el) {
        var rect = el.getBoundingClientRect();
        var docEl = document.documentElement;
        return {
            left: rect.left + (window.pageXOffset || docEl.scrollLeft || 0),
            top: rect.top + (window.pageYOffset || docEl.scrollTop || 0)
        };
    }

    function setDefHeaderImgWidth() {
        let left = getPageTopLeft(document.getElementById('defLogoContainer')).left
        let box = document.getElementById('defLogoContainer');
        let width = box.clientWidth;

        let fromLeft = left + width

        let intViewportWidth = window.innerWidth

        if(intViewportWidth <= 991) {
            fromLeft = 0;
        }

        defaultHeader.style.width = 'calc(100% - ' + fromLeft + 'px)'
    }

    /******************************
     * Project filter block resize
     *****************************/
    let projectFilters = document.getElementById('projectsFilters')

    if(projectFilters) {
        setProjectFilterWidth()
    }

    function setProjectFilterWidth() {
        let left = getPageTopLeft(document.getElementById('defLogoContainer')).left

        let box = document.getElementById('defLogoContainer');
        let width = box.clientWidth;

        let fromLeft = left + width - 75

        let intViewportWidth = window.innerWidth

        if(intViewportWidth <= 991) {
            fromLeft = 0;
        }

        if(intViewportWidth >= 1440) {
            fromLeft = width - 85
        }


        projectFilters.style.maxWidth = 'calc(100% - ' + fromLeft + 'px)'
    }

    /******************************
     *       Mobile menu
     *****************************/
    let mobileMenuBurger = document.getElementById('mobileMenu')
    let menuIsVisible = false

    // Listening click event
    mobileMenuBurger.addEventListener('click', function() {
        // animate burger
        if(menuIsVisible) {
            toggleBurger(false, mobileMenuBurger)
        } else {
            toggleBurger(true, mobileMenuBurger)
        }

        // show mobile menu
        if(menuIsVisible) {
            toggleMobileMenu(false)
            menuIsVisible = false
        } else {
            toggleMobileMenu(true)
            menuIsVisible = true
        }

    })

    function toggleBurger(activate, el) {
        if(activate) {
            el.classList.add('active')
            return
        }

        el.classList.remove('active')
    }

    function toggleMobileMenu(show) {
        let menu = document.getElementById('mobileMenuOverlay')

        if(show) {
            menu.classList.add('active')

            setTimeout(() => {
                menu.classList.add('visible')
            }, 100)

            setTimeout(() => {
                let interval = 100
                let elements = document.querySelectorAll('.mobile-menu-overlay .menu-button-list ul li')

                elements.forEach((el, index) => {
                    setTimeout(() => {
                        el.classList.add('visible')
                    }, interval * index)
                })

            }, 300)

            return
        }

            let interval = 100
            let elements = document.querySelectorAll('.mobile-menu-overlay .menu-button-list ul li')

            elements.forEach((el, index) => {
                setTimeout(() => {
                    el.classList.remove('visible')
                }, interval * index)
            })

            setTimeout(() => {
                menu.classList.remove('visible')

                setTimeout(() => {
                    menu.classList.remove('active')
                }, 100)
            }, interval * elements.length + 100)
    }

    window.onresize = () => {
        if(defaultHeader) {
            setDefHeaderImgWidth();
        }

        if(projectFilters) {
            setProjectFilterWidth()
        }
    }

    /******************************
     *   Project photo gallery
     *****************************/
    let galleryPhotoList = []
    let currentPhotoIndex = 0

    function getGalleryPhotos() {
        let galleryHolder = document.getElementById('galleryHolder')

        let photoList = galleryHolder.getAttribute('data-images')
        galleryPhotoList = JSON.parse(photoList)
    }

    function getCurrentImgIndex() {
        let previewImg = document.getElementById('previewImg')
        let url = previewImg.getAttribute('src')

        url = url.replace('.', '_full.')
        currentPhotoIndex = galleryPhotoList.findIndex(el => el === url)

        if(currentPhotoIndex < 0) {
            currentPhotoIndex = 0
        }
    }

    function nextGalleryImage() {
        currentPhotoIndex++

        if(currentPhotoIndex > (galleryPhotoList.length - 1)) {
            currentPhotoIndex = 0
        }

        showSelectedImage()
    }

    function prevGalleryImage() {
        currentPhotoIndex--

        if(currentPhotoIndex < 0) {
            currentPhotoIndex = galleryPhotoList.length - 1
        }

        showSelectedImage()
    }

    function showSelectedImage() {
        let galleryImg = document.getElementById('galleryImg')

        galleryImg.src = galleryPhotoList[currentPhotoIndex]
    }

    function openGallery() {
        getGalleryPhotos()
        getCurrentImgIndex()
        showSelectedImage()

        document.getElementById('galleryHolder').classList.add('active')
    }

    function closeGallery() {
        document.getElementById('galleryHolder').classList.remove('active')
        galleryPhotoList = []
        currentPhotoIndex = 0
    }

    if(document.getElementsByClassName('splide').length > 0) {
        let splide = new Splide( '.splide', {
            perPage: 4,
            perMove: 1,
            focus  : 'start',
            isNavigation    : true,
            breakpoints : {
                1200: {
                  perPage: 3,
                },
                900: {
                  perPage: 2,
                },
              },
        } )

        splide.mount()
    }

    function setPreviewImg() {
        setTimeout(() => {
            let img = document.querySelector('.splide__slide.is-active .photo-holder')
            let imgUrl = img.getAttribute('data-url')

            document.getElementById('previewImg').src = imgUrl;
        }, 500)
    }

    let projectPhotoGallery = document.getElementById('photoGallery')

    if(projectPhotoGallery) {
        document.querySelector('.splide__track').addEventListener('click', () => {
            setPreviewImg()
        })

        document.querySelectorAll('.splide__arrow').forEach(el => {
            el.addEventListener('click', () => {
                setPreviewImg()
            })
        })

        document.getElementById('prevGalleryImg').addEventListener('click', () => {
            prevGalleryImage()
        })

        document.getElementById('nextGalleryImg').addEventListener('click', () => {
            nextGalleryImage()
        })

        document.getElementById('openGallery').addEventListener('click', () => {
            openGallery()
        })

        document.getElementById('closeGallery').addEventListener('click', () => {
            closeGallery()
        })
    }

    /******************************
     *     Project calculator
     *****************************/
    let projectCalculator = document.getElementById('projectCalculator')

    if(projectCalculator) {
        let checkmarks = document.querySelectorAll('.checkmark')

        checkmarks.forEach(el => {
            el.addEventListener('click', function() {
                let index = el.getAttribute('data-index')
                let checkbox = document.getElementById('checkmark' + index)

                showCheckboxIcon(index, !checkbox.checked)

                setTimeout(() => {
                    calculatePercentageAndPrice()
                }, 10)
            })
        })
    }

    function showCheckboxIcon(index, show) {
        if(show) {
            document.getElementById('checkboxIcon' + index).classList.add('active')
            return
        }

        document.getElementById('checkboxIcon' + index).classList.remove('active')
    }

    /*****************************************
     * Calculate project price and percentage
     ****************************************/
    if(projectCalculator) {
        calculatePercentageAndPrice()
    }

    function calculatePercentageAndPrice() {
        let checkmarks = document.querySelectorAll('.checkmark')

        let totalPercentage = 0
        let totalPrice = 0

        let required = [
            'Bendroji dalis',
            'Sklypo plano dalis',
            'Architektūrinė dalis',
            'Vandentiekio ir nuotekų dalis',
            'Energetinio naudingumo vertinimas',
        ]

        let additional = [
            'Konstrukcijų dalis',
            'Statybos leidimo gavimo koordinavimas'
        ]

        let requiredActive = 0
        let additionalActive = 0
        let constructionPart = false

        checkmarks.forEach(el => {
            let price = el.getAttribute('data-price')
            let percentage = el.getAttribute('data-percentage')
            let index = el.getAttribute('data-index')
            let title = el.getAttribute('data-title')

            if( title != null) {
                let checkbox = document.getElementById('checkmark' + index)

                if(checkbox.checked) {
                    if(percentage.length > 0)
                        totalPercentage = totalPercentage + parseInt(percentage)

                    totalPrice = totalPrice + parseInt(price)

                    if(required.find(el => el === title) !== undefined)
                        requiredActive++;

                    if(additional.find(el => el === title) !== undefined)
                        additionalActive++;

                    if(title === 'Konstrukcijų dalis')
                        constructionPart = true
                }
            }
        })

        let stage = 1

        if(requiredActive === required.length && additionalActive === additional.length) {
            stage = 1
        } else if(!constructionPart && requiredActive === required.length) {
            stage = 2
        } else {
            stage = 3
        }

        showProjectText(stage)
        // changeShownPercentage(totalPercentage)
        changeShownPrice(totalPrice)
    }

    function changeShownPrice(price) {
        document.getElementById('totalPrice').innerText = price + ' €'
    }

    function showProjectText(stage) {
        let text = 'Projektas nepilnos apimties';

        if(stage === 1)
            text = 'Projektas paruoštas leidimui, statybai ir namo pridavimui'

        if(stage === 2)
            text = 'Projektas paruoštas statybos leidimui'

        let el = document.getElementById('totalPercentage')
        el.innerHTML = text
    }

    function changeShownPercentage(percentage) {
        let el = document.getElementById('totalPercentage')
        if(percentage >= 100) {
            el.innerHTML = `Projektas paruoštas leidimui, statybai ir namo pridavimui`
            return
        }

        el.innerHTML = `Projektas nepilnos apimties`
    }

    $('.sponsors-slide').slick({
        lazyLoad: 'ondemand',
        arrows: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: 'linear',
        slidesToShow: 5,
        slidesToScroll: 1,
        variableWidth: true,
        draggable: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 350,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    });

    /***************************************************
     * home page product card hover bottom line resize
     ***************************************************/
    if($('.our-projects').length > 0) {
        setBorderWidth(1)
        setBorderWidth(2)
        setBorderWidth(3)

        window.onresize = () => {
            setBorderWidth(1)
            setBorderWidth(2)
            setBorderWidth(3)
        }
    }

    function setBorderWidth(index) {
        let el = document.getElementById('hTitle' + index).clientWidth
        document.getElementById('hTitleLine' + index).style.width = 'calc(100% - 15px - ' + el + 'px)'
    }

    /*********************
     * testimonials slide
     ********************/
    $('#testimonialSlide').slick({
        arrows: false,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
            {
              breakpoint: 1921,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]
    });

    /******************************
     * About us history time line
     *****************************/

    let aboutUsClickHistory = $('.js-box-click')

    if(aboutUsClickHistory.length > 0) {
        addEventListenerHistory()
    }

    function addEventListenerHistory() {


        aboutUsClickHistory.on('click', function() {
            let children = $(this).parent()[0].children[2]

            $('.des.show.visible').addClass('old')

            if($('.old').parent().children().length > 0) {
                $('.old').parent().children()[1].classList.remove('active')
            }

            $('.old').removeClass('visible')

            setTimeout(() => {
                if($('.old').parent().children().length > 0) {
                    $('.old').parent().children()[1].classList.remove('active')
                }
                $('.old').removeClass('show')
                $('.old').removeClass('old')
            }, 200)


            $(this).addClass('active')

            children.classList.add('show')
            setTimeout(() => {
                children.classList.add('visible')
            }, 1)
        })
    }

    /******************************
     * Project view contact form
    *****************************/

    // projectPrice

    let contactButton = $('#getInTouch')
    let contactForm= $('#contactForm')

    if(contactButton.length > 0 && contactForm.length) {
        contactButton.on('click', function(e) {
            e.preventDefault()

            contactForm.addClass('active')
            setFormPriceValue()
            getSelectedServices()
        })

        $('#closeContactPopUp').on('click', () => {
            contactForm.removeClass('active')
        })
    }

    function setFormPriceValue() {
        let price =  $('#totalPrice').text()

        $('#projectPrice').val(price)
    }

    function getSelectedServices() {
        let checkmarks = document.querySelectorAll('.checkmark')
        let selected = []

        checkmarks.forEach(el => {
            let index = el.getAttribute('data-index')
            let title = el.getAttribute('data-title')

            let checkbox = document.getElementById('checkmark' + index)

            if(checkbox.checked) {
                if(title !== null)
                    selected.push(title)
            }
        })

        $('#projectServices').val(JSON.stringify(selected))
    }

    /******************************
     *      Smoth sckroll
    *****************************/
    $('.scroll-to').on('click', function(e) {
        e.preventDefault()

        $('.scroll-to.active').removeClass('active')

        $(this).addClass('active')

        let id = $(this).attr('href')
        scrollTo(id)
    })

    function scrollTo(id) {
        id = id.replace('#', '')

        let target = document.getElementById(id);
        target.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        })
    }

    /******************************
     *   Home page header slide
    *****************************/
    if(document.getElementById('slideImgContainer')) {
        let homePageSlide = new HomePageSlide()
        homePageSlide.init('nextSlideHome', 'prevSlideHome', 'slideImg', 'slideText', 5000)
    }


});
