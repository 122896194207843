import {isInViewport} from './helpers'
import {callCounter} from './counter'

window.addEventListener('load', () => {
    setTimeout(() => {
        initAnimations()
    }, 100)
})
window.addEventListener('scroll', initAnimations)

function initAnimations() {
    let elements = document.querySelectorAll('.animate:not(.-active)')
    // Animation speed
    let interval = 100

    // iteration of animation
    let element = 0

    elements.forEach(el => {
        // CHeck if element are in view
        let inView = isInViewport(el)

        if(inView) {
            // Set animation delay based on element index
            setTimeout(() => {
                // add active class to start animation
                el.classList.add('-active')

                // if animations contains counter class start js counter
                if(typeof callCounter !== 'undefined' && el.classList.contains('js-counter')) {
                    callCounter(el.getAttribute('id'))
                }

            }, interval * element)

            // increase element count
            element++
        }
    })
}
